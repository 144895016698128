<template>
  <v-app>
    <v-container
      id="forgot-pwd-container"
      fluid
      tag="section"
      class="fill-height"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            elevation="24"
            class="px-4 py-7"
          >
            <v-form
              @submit.prevent="submit"
            >
              <v-row
                justify="center"
              >
                <v-col
                  cols="10"
                >
                  <v-text-field
                    outlined
                    label="Identifiant (e-mail)"
                    class="purple-input"
                    type="email"
                    v-model="username"
                  />
                </v-col>
              </v-row>
              <v-row
                justify="center"
              >
                <v-col
                  cols="10"
                >
                  <v-btn
                    type="submit"
                    color="primary"
                    block
                    large
                    :disabled="sendDisabled"
                  >
                    Envoyer un lien de réinitialisation de mot de passe
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dashboard-core-alert-snackbar />
  </v-app>
</template>

<script>

  import { mapMutations } from 'vuex';

  export default {
    name: 'Login',

    components: {
      DashboardCoreAlertSnackbar: () => import('../dashboard/components/core/AlertSnackbar'),
    },

    data() {
      return {
        username: '',
        sendDisabled: false,
      }
    },

    methods: {
      submit() {
        this.sendDisabled = true;
        this.axios.post('/users/resetPassword/sendToken?email=' + this.username)
          .then((res) => {
            this.setSnack({ text: 'Un e-mail contenant les instructions pour réinitialiser votre mot de passe a été envoyé à ' + this.username, type: 'success' });
            this.$router.push({ name: 'Login' })
          })
          .catch((e) => {
            this.setSnack({ text: 'Une erreur s\'est produite', type: 'warn' });
            this.sendDisabled = false;
          });
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #forgot-pwd-container {
    min-height: 100vh;
    background-image: url('../../assets/wallpaper-login.jpg');
    background-size: cover;
    background-position: center;
  }

  .cursor-pointer {
    cursor: pointer;
  }

</style>
